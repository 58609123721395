import Splide from '@splidejs/splide';
import { Fancybox } from "@fancyapps/ui";

var elms = document.getElementsByClassName('splide');

for ( var i = 0; i < elms.length; i++ ) {
  new Splide( elms[ i ] ).mount();
}

Fancybox.bind("[data-fancybox]", {});

Fancybox.bind('[data-fancybox="gallery"], [data-fancybox="services-girl"], [data-fancybox="services-man"]', {
    Thumbs: {
        type: "classic",
    },
    Toolbar: {
        display: {
            left: [],
            middle: [],
            right: ["close"],
        },
    },
    Navigation: true,
    hideOnContentClick: false,
    on: {
        loading: (fancybox, slide) => {
            document.querySelector('.fancybox__container').classList.add('gallery-modal');
        },
    },
});




class Tabs {
    constructor(container) {
        this.container = container;
        this.tabBtns = this.container.querySelectorAll(".js-tab-btn");
        this.tabContents = this.container.querySelectorAll(".js-tab");
        
        this.tabBtns.forEach((el, i) => {
          el.addEventListener("click", () => this.openTab(i));
        });
    }
    
    removeTab(elementList) {
        elementList.forEach((el) => {
            el.classList.remove("is-active");
        });
    }
    
    openTab(index) {
        this.removeTab(this.tabBtns);
        this.removeTab(this.tabContents);
        
        this.tabBtns[index].classList.add("is-active");
        this.tabContents[index].classList.add("is-active");
    }
    }
  
const sections = document.querySelectorAll(".js-section-tabs");
  
sections.forEach((section) => {
    new Tabs(section);
});
  

var headers = document.querySelectorAll('.services-card__header');

headers.forEach(function(header) {
    header.addEventListener('click', function() {
        var card = this.closest('.services-card');
        if (card.classList.contains('active')) {
            card.classList.remove('active');
        } else {
            card.classList.add('active');
        }
    });
});


const loadButtons = document.querySelectorAll('.js-load-services');

loadButtons.forEach(button => {
    button.addEventListener('click', function() {
        const tab = this.closest('.section__tab');
        tab.classList.add('loaded');
        tab.scrollIntoView({ behavior: 'smooth' });
        this.remove();
    });
});

document.addEventListener("DOMContentLoaded", function() {
    var headerCity = document.querySelector(".header__city");
    if (headerCity.textContent.trim() !== "Выбрать город") {
        var cityModalLinks = document.querySelectorAll(".city-modal__link");
        cityModalLinks.forEach(function(link) {
            if (link.textContent.trim() === headerCity.textContent.trim()) {
                link.classList.add("current");
            }
        });
    }
});

class Menu {
    constructor() {
        this.menuBtn = document.querySelector('.header__menu-btn');
        this.cityBtn = document.querySelectorAll('.js-select-city');
        this.closeButtons = document.querySelectorAll('.js-close');
        this.closeLinks = document.querySelectorAll('.js-scroll-to');
        this.cityBackBtn = document.querySelector('.city-modal__back');

        this.init();
    }

    init() {
        this.menuBtn.addEventListener('click', () => this.openMenu());
        this.closeButtons.forEach(button => button.addEventListener('click', () => this.closeAll()));
        this.closeLinks.forEach(button => button.addEventListener('click', () => this.closeAll()));
        this.cityBackBtn.addEventListener('click', () => this.closeCity());
        this.cityBtn.forEach(button => button.addEventListener('click', () => this.openCity()));
    }

    openMenu() {
        document.body.classList.add('menu-opened');
    }

    openCity() {
        document.body.classList.add('city-opened');
    }

    closeAll() {
        document.body.classList.remove('menu-opened');
        document.body.classList.remove('city-opened');
    }

    closeCity() {
        document.body.classList.remove('city-opened');
    }
}

const menu = new Menu();

var eventCalllback = function (e) {
    var el = e.target,
    clearVal = el.dataset.phoneClear,
    pattern = el.dataset.phonePattern,
    matrix_def = "+7 (___) ___-__-__",
    matrix = pattern ? pattern : matrix_def,
    i = 0,
    def = matrix.replace(/\D/g, ""),
    val = e.target.value.replace(/\D/g, "");
    if (clearVal !== 'false' && e.type === 'blur') {
        if (val.length < matrix.match(/([\_\d])/g).length) {
            e.target.value = '';
            return;
        }
    }
    if (def.length >= val.length) val = def;
    e.target.value = matrix.replace(/./g, function (a) {
        return /[_\d]/.test(a) && i < val.length ? val.charAt(i++) : i >= val.length ? "" : a
    });
  }
  var phone_inputs = document.querySelectorAll('input[type="tel"]');
  for (let elem of phone_inputs) {
    for (let ev of ['input', 'blur', 'focus']) {
      elem.addEventListener(ev, eventCalllback);
    }
  }

  // Функция для плавной прокрутки к якорю
function smoothScroll(target, duration) {
    // Получаем элемент, к которому нужно прокрутиться
    var targetElement = document.querySelector(target);
    // Получаем начальную позицию скролла
    var startPosition = window.pageYOffset;
    // Вычисляем расстояние, на которое нужно прокрутиться
    var targetPosition = targetElement.getBoundingClientRect().top + startPosition;
    // Начальное время анимации
    var startTime = null;
  
    // Функция анимации
    function animation(currentTime) {
      if (startTime === null) startTime = currentTime;
      var timeElapsed = currentTime - startTime;
      var run = ease(timeElapsed, startPosition, targetPosition, duration);
      window.scrollTo(0, run);
      if (timeElapsed < duration) requestAnimationFrame(animation);
    }
  
    // Функция для создания плавной анимации
    function ease(t, b, c, d) {
      t /= d / 2;
      if (t < 1) return c / 2 * t * t + b;
      t--;
      return -c / 2 * (t * (t - 2) - 1) + b;
    }
  
    // Запускаем анимацию
    requestAnimationFrame(animation);
  }
  
  // Назначаем обработчик события для клика по ссылкам с якорями
  document.querySelectorAll('.js-scroll-to').forEach(anchor => {
    anchor.addEventListener('click', function(e) {
      e.preventDefault();
      var target = this.getAttribute('href');
      var duration = 1000; // Продолжительность анимации в миллисекундах
      smoothScroll(target, duration);
    });
  });
  


if(document.querySelector('.select-city__button')){
    document.querySelector('.select-city__button').addEventListener('click', function(){
        this.parentNode.classList.add('full');
        this.remove();
    });
}



let formsStatic = document.querySelectorAll('.modal form');

formsStatic.forEach(function(form) {
    let name = form.querySelector('input[type="text"]');
    let tel = form.querySelector('input[type="tel"]');
    let textarea = form.querySelector('textarea');
    let ym_goal = form.getAttribute('ym_goal');

    form.addEventListener('submit', function(e) {
      const hiddenInput = form.querySelector('input[id="fb_type"]');
      const checkedRadio = form.querySelector('input[type="radio"]:checked');
      
      if (checkedRadio) {
        const labelSpan = checkedRadio.nextElementSibling;
        if (labelSpan) {
          hiddenInput.value = labelSpan.textContent.trim();
          console.log(hiddenInput.value);
        }
      }

      if(name){
        let nameValue = name.value.trim();
        if (nameValue.length <= 2) {
          name.parentNode.classList.add('error');
        } else {
          name.parentNode.classList.remove('error');
        }
      }

      if(tel){
        let telValue = tel.value.trim();

        if (telValue.length <= 11) {
          tel.parentNode.classList.add('error');
        } else {
          tel.parentNode.classList.remove('error');
        }
      }

      if (name.value.length >= 3 && tel.value.length >= 11) {
        e.preventDefault();
        var request = new XMLHttpRequest();
        request.onreadystatechange = function() { 
          if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
            console.log('success');
            document.querySelector('body').classList.add('is-modal');
            document.querySelector('.js-success-modal').style.display = 'inline-flex';
            ym(97670533, 'reachGoal', ym_goal);

            if(name){
              name.value = "";
            }

            if(tel){
              tel.value = "";
            }

            if(textarea){
              textarea.value = "";
            }
          }
      }

        request.open(form.method, form.action, true);

        var data = new FormData(form);
        request.send(data);
      } else {
        e.preventDefault();
      }
    });
});

var succesClose = document.querySelectorAll('.js-success-modal .js-close-modal');

succesClose.forEach(function(item){

    item.addEventListener('click', function(e) {
        document.querySelector('body').classList.remove('is-modal');
        document.querySelector('.js-success-modal').style.display = 'none';
    });

}); 



var closeModalSuccess = document.querySelectorAll('.thanks__close');

closeModalSuccess.forEach(function(item){

    item.addEventListener('click', function(e) {
        // Вызов метода закрытия Fancybox
        if (typeof Fancybox !== 'undefined') {
            Fancybox.close();
            document.querySelector('.js-success-modal').style.display = 'none';
            setTimeout(function(){
                document.querySelector('body').classList.remove('is-modal');
            }, 400);
        } else if (typeof $.fancybox !== 'undefined') {
            $.fancybox.close();
            document.querySelector('.js-success-modal').style.display = 'none';
            setTimeout(function(){
                document.querySelector('body').classList.remove('is-modal');
            }, 400);
        }
    });
}); 

Fancybox.bind("[data-fancybox]", {
    on: {
        close: (fancybox, slide) => {
            document.querySelector('.js-success-modal').style.display = 'none';
            setTimeout(function(){
                document.querySelector('body').classList.remove('is-modal');
            }, 400);
        }
    }
});
